import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Step,
  StepLabel,
  Stepper,
  Typography,
  Grid,
} from "@mui/material";
import MyButton from "../Commons/MyButton";
import { notify } from "../../helper/notify";
import useAxios from "../../hooks/useAxios";
import TableComponent from "../Tables/TableComponent";
import { yuklenilecekKDVColumns } from "../../helper/columns";
import ExportData from "../Commons/ExportData";
import EnvanterKaydi from "../Islemler/EnvanterKaydi";
import LoadingData from "../Commons/LoadingData";
import ErrorData from "../Commons/ErrorData";
import { newCustomer } from "../../helper/initialData";
import IndirimliKayitForm from "../Forms/IndirimliKayitForm";
import KDVBeyannamesiKaydi from "../Islemler/KDVBeyannamesiKaydi";

const IndirimliOranListesi = ({ tckn }) => {
  const [activeStep, setActiveStep] = useState(0);

  // KDV verisi
  const [yuklenecekKDVData, setYuklenecekKDVData] = useState([]);
  const [exportDate, setExportDate] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const axiosWithToken = useAxios();
  const [formData, setFormData] = useState(() => {
    const defaultValue = {
      id: null,
      name: "",
      registration_no: "",
      registration_date: "",
      building_no: "",
      total_floors: "",
      sections: {
        mesken: { count: "", area: "" },
        ortak: { count: "", area: "" },
      },
      project_id: "",
      building_id: "",
      housing_details: [],
    };

    return defaultValue;
  });

  // Modal aç/kapat
  const [open, setOpen] = useState(false);

  // Modal’da hangi bölümü göstereceğimizi tutan state (1, 4 veya 5)
  const [formSection, setFormSection] = useState(null);

  const handleOpen = (section) => {
    setFormSection(section);
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  // Farklı veri çekme işlemi yapabilirsiniz. Örnek getData:
  const getData = async (year, month) => {
    // year = year ? year : exportDate?.year;
    // month = month ? month : exportDate?.month;
    // ...
    // try/catch ile veriyi çekebilirsiniz.
    // setYuklenecekKDVData(data);
  };

  // tckn değişince stepper’ı sıfırla
  useEffect(() => {
    setYuklenecekKDVData([]);
    setActiveStep(0);
  }, [tckn]);

  // Step içeriği
  const steps = [
    {
      label: "Proje Ait Bilgileri Yükle",
      description:
        "Proje (mükellef) kaydı oluşturup devam etmek için bu adımı tamamlayın.",
      content: (
        <Grid item xs="auto" md="auto">
          <MyButton
            variant="contained"
            color="btnActiveColor"
            onClick={() => handleOpen(1)} // formSection=1
            title="YENİ PROJE KAYDI OLUŞTUR"
            size="small"
            sx={{
              width: "auto",
              minWidth: "120px",
              padding: "6px 16px",
              color: "#EEEEEE",
            }}
          />
        </Grid>
      ),
    },
    {
      label: "Fatura Kaydı Yükle",
      description:
        "Fatura kaydı yüklemek için bu adımı tamamlayın. Ardından devam edebilirsiniz.",
      content: <EnvanterKaydi tckn={tckn} />,
    },
    {
      label: "KDV Beyannamesi Kaydı Yükle",
      description:
        "KDV Beyannamesi (GÇB vb.) kaydı yüklemek için gerekli bilgileri sağlayın.",
      content: <KDVBeyannamesiKaydi tckn={tckn} getIadeyeEsasKDV={getData} />,
    },
    {
      label: "İşçi İstatistikleri",
      description:
        "İşçi sayısı ve gider bilgilerini gireceğiniz adım. Tamamladığınızda devam edebilirsiniz.",
      content: (
        <Grid item xs="auto" md="auto">
          <MyButton
            variant="contained"
            color="btnActiveColor"
            onClick={() => handleOpen(4)} // formSection=4
            title="İŞÇİ İSTATİSTİK KAYDI OLUŞTUR"
            size="small"
            sx={{
              width: "auto",
              minWidth: "120px",
              padding: "6px 16px",
              color: "#EEEEEE",
            }}
          />
        </Grid>
      ),
    },
    {
      label: "Yapı Detay Tablosu",
      description:
        "Bina, mesken/ortak alan ve daire bilgilerini gireceğiniz adım.",
      content: (
        <Grid item xs="auto" md="auto">
          <MyButton
            variant="contained"
            color="btnActiveColor"
            onClick={() => handleOpen(5)} // formSection=5
            title="YAPI DETAY KAYDI OLUŞTUR"
            size="small"
            sx={{
              width: "auto",
              minWidth: "120px",
              padding: "6px 16px",
              color: "#EEEEEE",
            }}
          />
        </Grid>
      ),
    },
  ];

  // Stepper ile adım atlama
  const handleStepClick = (stepIndex) => {
    setActiveStep(stepIndex);
  };
  const handleNext = () => {
    if (activeStep < steps.length - 1) {
      setActiveStep((prevStep) => prevStep + 1);
    }
  };
  const handleBack = () => {
    if (activeStep > 0) {
      setActiveStep((prevStep) => prevStep - 1);
    }
  };

  return (
    <Box sx={{ p: 2 }}>
      <Typography color="error" variant="body2" mb={2}>
        *Eğer daha önce bu işlemleri yapmadıysanız lütfen önce yükleme
        işlemlerini tamamlayınız!
      </Typography>

      <Stepper activeStep={activeStep} orientation="vertical">
        {steps.map((step, index) => (
          <Step key={step.label}>
            <StepLabel
              onClick={() => handleStepClick(index)}
              sx={{
                cursor: "pointer",
                "& .MuiStepIcon-root": {
                  fontSize: "2.5rem",
                  color: activeStep === index ? "primary.main" : "grey.500",
                },
              }}
            >
              <Typography
                variant={activeStep === index ? "h6" : "body1"}
                sx={{ fontWeight: activeStep === index ? "bold" : "normal" }}
              >
                {step.label}
              </Typography>
            </StepLabel>
            {activeStep === index && (
              <Box sx={{ mt: 2 }}>
                <Typography variant="body2" color="textSecondary" mb={1}>
                  {step.description}
                </Typography>
                {step.content}

                {/* Geri-İleri butonları */}
                <Box sx={{ mt: 2 }}>
                  {index < steps.length - 1 && (
                    <Button
                      variant="contained"
                      onClick={handleNext}
                      sx={{ mr: 1 }}
                    >
                      Devam Et
                    </Button>
                  )}
                  {index > 0 && (
                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={handleBack}
                      sx={{ mr: 1 }}
                    >
                      Geri
                    </Button>
                  )}
                </Box>
              </Box>
            )}
          </Step>
        ))}
      </Stepper>

      {/* Loading / Error Durumları */}
      {loading && <LoadingData />}
      {error && <ErrorData error={error} />}

      {/* Eğer KDV tablosu verisi varsa göster */}
      {Array.isArray(yuklenecekKDVData) && yuklenecekKDVData.length > 0 && (
        <Box sx={{ mt: 3 }}>
          <TableComponent
            data={yuklenecekKDVData}
            tableColumns={yuklenilecekKDVColumns}
          />
          <Grid container spacing={2} mt={2}>
            <Grid item>
              <ExportData
                url={`api/yuklenilecek_kdv/yuklenim_arti_gcb_merge?tckn=${tckn}&year=${exportDate?.year}&month=${exportDate?.month}&export=excel`}
                dosyaAdi={`yuklenilecek_KDV_listesi-${exportDate?.month}-${exportDate?.year}.xlsx`}
              />
            </Grid>
            <Grid item>
              <MyButton
                title="Kapat"
                variant="contained"
                color="btnActiveColor"
                onClick={() => setYuklenecekKDVData([])}
              />
            </Grid>
          </Grid>
        </Box>
      )}

      {/* Modal: formSection'a göre çeşitli alanlar */}
      {open && (
        <IndirimliKayitForm
          isVisible={open}
          toggleModal={handleClose}
          initialState={newCustomer}
          formSection={formSection}
          formData={formData}
          setFormData={setFormData}
        />
      )}
    </Box>
  );
};

export default IndirimliOranListesi;

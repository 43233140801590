// src/services/indirimliOran.js

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const indirimliOranApi = createApi({
  reducerPath: "indirimliOranApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
    prepareHeaders: (headers, { getState }) => {
      const token = getState().auth.token;
      if (token) {
        headers.set("Authorization", `Token ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    // 1) Proje oluşturma/seçme
    createOrSelectProject: builder.mutation({
      query: (payload) => ({
        url: "api/indirimli_oran/create_or_select_project/",
        method: "POST",
        body: payload, 
      }),
    }),

    // 2) İşçi istatistikleri ekleme
    addWorkerStatistics: builder.mutation({
      query: (payload) => ({
        url: "api/indirimli_oran/add_worker_statistics/",
        method: "POST",
        body: payload,
      }),
    }),

    // 3) Mesken/Ortak alan bilgisi ekleme
    addIndependentSection: builder.mutation({
      query: (payload) => ({
        url: "api/indirimli_oran/add_independent_section/",
        method: "POST",
        body: payload,
      }),
    }),

    // 4) Bina oluşturma
    createBuilding: builder.mutation({
      query: (payload) => ({
        url: "api/indirimli_oran/create_building/",
        method: "POST",
        body: payload,
      }),
    }),

    // 5) Daire (housing_details) ekleme
    addHousingDetails: builder.mutation({
      query: (payload) => ({
        url: "api/indirimli_oran/add_housing_details/",
        method: "POST",
        body: payload,
      }),
    }),
  }),
});

// RTK Query hook’larını export ediyoruz
export const {
  useCreateOrSelectProjectMutation,
  useAddWorkerStatisticsMutation,
  useAddIndependentSectionMutation,
  useCreateBuildingMutation,
  useAddHousingDetailsMutation,
} = indirimliOranApi;

import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  CircularProgress,
  Typography,
  Box,
  List,
  ListItem,
  ListItemText,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import MyButton from "../Commons/MyButton";
import useAxios from "../../hooks/useAxios";
import JSZip from "jszip";
import { notify } from "../../helper/notify";

const message = {
  upload: "Dosyalarınız Yükleniyor...",
  process: "Dosyalarınız işleniyor...",
};

const MAX_FILES = 250; // Maksimum dosya adedi
const MAX_TOTAL_SIZE_MB = 100; // Maksimum toplam boyut (MB)
const MAX_TOTAL_SIZE_BYTES = MAX_TOTAL_SIZE_MB * 1024 * 1024;

const UploadFilesForm = ({
  setData,
  tckn,
  url,
  secondUrl,
  folderType,
  title,
  getIslenmisFaturalar,
}) => {
  const [open, setOpen] = useState(false);
  const [fileBatchError, setFileBatchError] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [dragActive, setDragActive] = useState(false);

  // Backend’den gelen hata mesajını göstermek için kullanılacak state
  const [backendErrorMessage, setBackendErrorMessage] = useState("");

  const axiosWithToken = useAxios();

  const successSound = new Audio("/success.mp3");
  const errorSound = new Audio("/error.mp3");

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setFileBatchError("");
    setSelectedFiles([]);
    setBackendErrorMessage("");
  };

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    setSelectedFiles(files);
    validateFiles(files);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setDragActive(true);
  };

  const handleDragLeave = () => setDragActive(false);

  const handleDrop = (e) => {
    e.preventDefault();
    setDragActive(false);
    const files = Array.from(e.dataTransfer.files);
    setSelectedFiles(files);
    validateFiles(files);
  };

  const validateFiles = (files) => {
    const totalSize = files.reduce((acc, file) => acc + file.size, 0);

    if (files.length > MAX_FILES) {
      setFileBatchError(
        `Maksimum ${MAX_FILES} dosya ve maksimum 100MB'a kadar yükleme yapabilirsiniz. Lütfen fazlalıkları kaldırınız.`
      );
    } else if (totalSize > MAX_TOTAL_SIZE_BYTES) {
      setFileBatchError(
        `Toplam dosya boyutu ${MAX_TOTAL_SIZE_MB} MB'ı aşamaz.`
      );
    } else {
      setFileBatchError("");
    }
  };

  const handleRemoveFile = (index) => {
    if (!loading) {
      const updatedFiles = selectedFiles.filter((_, i) => i !== index);
      setSelectedFiles(updatedFiles);
      validateFiles(updatedFiles);
    }
  };

  // XML ve/veya ZIP dosyalarını tek bir ZIP içinde birleştirme
  const processFiles = async () => {
    const zip = new JSZip();

    // Seçili dosyaları gözden geçir, .xml veya .zip olanları ekle
    selectedFiles.forEach((file) => {
      if (file.name.endsWith(".xml") || file.name.endsWith(".zip")) {
        zip.file(file.name, file);
      }
    });

    // Nihai zip dosyasını oluştur
    const finalZipBlob = await zip.generateAsync({ type: "blob" });

    // Yeni bir dosya nesnesi oluştururken MIME tipini belirt
    const zipFile = new File([finalZipBlob], "final_files.zip", {
      type: "application/zip",
    });

    return zipFile;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Eğer validasyon hatası varsa veya dosya seçilmediyse
    if (fileBatchError || selectedFiles.length === 0) {
      notify("Lütfen geçerli dosyalar seçiniz ve yeniden deneyin.", "error");
      errorSound.play();
      return;
    }

    setLoading("upload");
    setBackendErrorMessage(""); // Her yeni yüklemede önce sıfırla

    try {
      // Dosyaları tek bir ZIP olarak hazırlıyoruz
      const finalZipFile = await processFiles();

      // FormData oluştur
      const data = new FormData();
      data.append("files", finalZipFile);
      data.append("tckn", tckn);
      data.append("folder_type", folderType);

      // İlk isteği gönder
      const res = await axiosWithToken.post(url, data);

      // Eğer backend özel bir "error" mesajı döndürüyorsa:
      if (res.data?.error) {
        // Bu mesajı kullanıcıya göster
        notify(res.data.error, "error");
        errorSound.play();
        setBackendErrorMessage(res.data.error);
        return; // Başarılı akışa geçmeden fonksiyonu bitir
      }

      // Başarılı durum kontrolü
      if (res.status === 200) {
        setLoading("process");
        // İkinci URL çağrısı
        const response = await axiosWithToken.post(secondUrl, {
          tckn,
          folder_type: folderType,
        });

        if (response.status === 200) {
          handleApiResponse(response);
          notify("Dosyalarınız başarıyla yüklendi ve işlendi.", "success");
          successSound.play();
        } else {
          notify(
            "Dosyalar yüklendi ancak veri işleme sırasında bir hata oluştu!",
            "error"
          );
          errorSound.play();
          setBackendErrorMessage(
            "Dosyalar yüklendi ancak veri işleme sırasında bir hata oluştu!"
          );
        }
      } else {
        notify("Dosyalarınızın yüklenmesi sırasında bir hata oluştu!", "error");
        errorSound.play();
        setBackendErrorMessage(
          "Dosyalarınızın yüklenmesi sırasında bir hata oluştu!"
        );
      }

      handleClose();
    } catch (error) {
      console.error("Hata:", error);

      // Eğer catch’e düşmüşsek, backend "error" mesajı göndermiş olabilir
      if (error?.response?.data?.error) {
        notify(error.response.data.error, "error");
        setBackendErrorMessage(error.response.data.error);
      } else {
        notify("Dosyalarınızın yüklenmesi sırasında bir hata oluştu!", "error");
        setBackendErrorMessage(
          "Dosyalarınızın yüklenmesi sırasında bir hata oluştu!"
        );
      }
      errorSound.play();
    } finally {
      setLoading(false);
    }
  };

  // Backend’den dönen veriyi "fatura", "gcb", vb. durumlara göre işleme
  const handleApiResponse = async (response) => {
    if (folderType === "fatura") {
      if (getIslenmisFaturalar) {
        getIslenmisFaturalar();
      } else {
        setData({
          alislarTemelData: JSON.parse(response.data.alislar_temel),
          alislarIstisnaData: JSON.parse(response.data.alislar_istisna),
          satislarTemelData: JSON.parse(response.data.satislar_temel),
          satislarIstisnaData: JSON.parse(response.data.satislar_istisna),
        });
      }
    } else if (folderType === "gcb") {
      setData(JSON.parse(response.data.gcbler));
    } else if (folderType === "beyanname") {
      setData(true);
    }
  };

  return (
    <>
      <MyButton
        variant="contained"
        color="btnActiveColor"
        onClick={handleOpen}
        title={title}
      />
      <Dialog open={open}>
        <DialogTitle>
          <Typography variant="h6">{title}</Typography>
          <Typography variant="body2">Mükellef TCKN/VKN : {tckn}</Typography>
        </DialogTitle>

        <DialogContent>
          {/* Kendi validasyon hatası */}
          {fileBatchError && (
            <Typography color="error" variant="body2">
              {fileBatchError}
            </Typography>
          )}

          {/* Backend'den gelen özel hata mesajı */}
          {backendErrorMessage && (
            <Typography color="error" variant="body2">
              {backendErrorMessage}
            </Typography>
          )}

          <Box
            onClick={() =>
              !loading && document.getElementById("fileInput").click()
            }
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
            sx={{
              border: dragActive ? "2px solid blue" : "2px dashed gray",
              padding: "16px",
              textAlign: "center",
              cursor: "pointer",
              marginTop: "8px",
              marginBottom: "8px",
            }}
          >
            <Typography>Dosyaları sürükleyip bırakın veya tıklayın.</Typography>
            <input
              type="file"
              multiple
              accept={
                folderType === "yuklenim" ? ".xls,.xlsx,.csv" : ".xml,.zip"
              }
              style={{ display: "none" }}
              id="fileInput"
              onChange={handleFileChange}
              disabled={loading}
            />
          </Box>

          <Typography color="secondary.second" variant="body2">
            *Seçtiğiniz dosyaların işlenebilmesi için Yükle butonuna tıklayınız!
          </Typography>

          {selectedFiles.length > 0 && (
            <List
              className="myscrool"
              sx={{ maxHeight: "75px", overflowY: "auto", marginTop: "8px" }}
            >
              {selectedFiles.map((file, index) => (
                <ListItem
                  key={index}
                  secondaryAction={
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      onClick={() => handleRemoveFile(index)}
                      disabled={Boolean(loading)}
                    >
                      <CloseIcon />
                    </IconButton>
                  }
                >
                  <ListItemText primary={file.name} />
                </ListItem>
              ))}
            </List>
          )}

          {/* Yükleme süreci devam ederken gösterilecek buton */}
          {loading ? (
            <>
              <MyButton
                size="normal"
                variant="contained"
                type="button"
                disabled
                startIcon={<CircularProgress />}
                title={message[loading] || "İşlem yapılıyor..."}
              />
              <Typography color="secondary.second" variant="body2">
                İşlem süresi dosyaların boyutuna ve sayısına göre değişkenlik
                gösterebilir. İşlem bittiğinde yükleme penceresi otomatik olarak
                kapanacaktır.
              </Typography>
            </>
          ) : (
            <MyButton
              size="normal"
              variant="contained"
              color="btnActiveColor"
              onClick={handleSubmit}
              title="Yükle"
              disabled={!!fileBatchError || selectedFiles.length === 0}
            />
          )}
        </DialogContent>

        <DialogActions>
          <Button
            onClick={handleClose}
            color="btnColor"
            disabled={Boolean(loading)}
          >
            Kapat
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default UploadFilesForm;

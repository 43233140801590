import React, { useEffect, useState } from "react";
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Typography,
  Avatar,
  Grid,
  TextField,
  Paper,
  IconButton,
  Button,
  ToggleButtonGroup,
  ToggleButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import { useSelector } from "react-redux";
import useAuthCall from "../hooks/useAuthCall";
import MyButton from "../components/Commons/MyButton";
import Loading from "../components/Commons/Loading";
import PasswordChangeForm from "../components/Forms/PasswordChangeForm";

// Kullanıcı tipi için label eşlemeleri
const meslek = {
  ymm: "Yeminli Mali Müşavir",
  smm: "Serbest Mali Müşavir",
};

// FormData'ya eklenecek değerin dosya türünde olup olmadığını kontrol eden fonksiyon
function isFile(value) {
  return value instanceof File || value instanceof Blob;
}

// Tam URL mi, yoksa relative path mi, yoksa null mü diye kontrol eden yardımcı fonksiyon
function getImageUrl(imagePath) {
  if (!imagePath) {
    // null, undefined veya boş string ise "" döndür (hiç resim göstermesin)
    return "";
  }
  // Zaten "http" ile başlıyorsa, tam URL'dir; tekrar base URL eklemeye gerek yok
  if (imagePath.startsWith("http")) {
    return imagePath;
  }
  // Değilse kendi domainimizi ekleyip geri döndürelim
  return process.env.REACT_APP_BASE_URL + imagePath;
}

// MUI styled bileşenler
const StyledCard = styled(Card)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  backgroundColor: theme.palette.tableColor.main,
  color: theme.palette.secondary.second,
  position: "relative",
}));

const ProfileAvatar = styled(Avatar)(({ theme }) => ({
  width: 120,
  height: 120,
  border: `2px solid ${theme.palette.secondary.second}`,
  position: "absolute",
  left: "50%",
  transform: "translate(-50%,-60%)",
  zIndex: 2,
}));

const Input = styled("input")({
  display: "none",
});

const Profile = () => {
  const { userProfile, loading } = useSelector((state) => state.auth);
  const { updateUser, getUser } = useAuthCall();

  // State'ler
  const [profilePicture, setProfilePicture] = useState("");
  const [backgroundPicture, setBackgroundPicture] = useState("");
  const [formData, setFormData] = useState({});
  const [selectedRate, setSelectedRate] = useState(10);
  const [openDialog, setOpenDialog] = useState(false);

  // Sayfa yüklendiğinde kullanıcıyı çek
  useEffect(() => {
    getUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // userProfile değiştikçe formu ve resimleri güncelle
  useEffect(() => {
    setFormData(userProfile);
    setProfilePicture(getImageUrl(userProfile?.profile_picture));
    setBackgroundPicture(getImageUrl(userProfile?.firmanizin_logosu));
  }, [userProfile]);

  // Metin alanlarındaki değişiklikleri formData'ya yaz
  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  // Profil fotoğrafı seçince, formData'ya dosyayı koyup ekranda önizleme için blob URL oluştur
  const handleProfilePictureChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.files[0] });
    setProfilePicture(URL.createObjectURL(e.target.files[0]));
  };

  // Arkaplan resmi/firmanızın logosu seçince, aynı mantık
  const handleBackgroundPictureChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.files[0] });
    setBackgroundPicture(URL.createObjectURL(e.target.files[0]));
  };

  // Form submit
  const handleSubmit = (e) => {
    e.preventDefault();
    const updatedData = new FormData();
    for (const key in formData) {
      if (key === "profile_picture") {
        if (isFile(formData[key])) updatedData.append(key, formData[key]);
      } else if (key === "firmanizin_logosu") {
        if (isFile(formData[key])) updatedData.append(key, formData[key]);
      } else {
        updatedData.append(key, formData[key]);
      }
    }
    updateUser(updatedData);
  };

  // Referans link oranı değişince
  const handleRateChange = (event, newRate) => {
    if (newRate !== null) {
      setSelectedRate(newRate);
    }
  };

  // Dialog aç/kapa
  const handleCreateReferralLink = () => {
    setOpenDialog(true);
  };
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  if (loading) return <Loading />;

  return (
    <Box sx={{ flexGrow: 1, p: 3 }}>
      <Box sx={{ display: "flex", justifyContent: "end", my: 1 }}>
        <PasswordChangeForm />
      </Box>
      <Box
        component="form"
        encType="multipart/form-data"
        onSubmit={handleSubmit}
      >
        <Grid container spacing={3}>
          {/* SOL SÜTUN: Profil Kartı ve Logo */}
          <Grid item xs={12} lg={4}>
            <StyledCard>
              {backgroundPicture ? (
                <CardMedia
                  component="img"
                  height="200"
                  image={backgroundPicture}
                  alt="Firmanızın Logosu"
                  sx={{ objectFit: "cover" }}
                />
              ) : (
                // Aynı boş alan
                <Box sx={{ height: 200, width: "100%" }} />
              )}

              <label htmlFor="background-picture">
                <Input
                  accept="image/*"
                  id="background-picture"
                  type="file"
                  name="firmanizin_logosu"
                  onChange={handleBackgroundPictureChange}
                />
                <IconButton
                  color="primary"
                  aria-label="upload picture"
                  component="span"
                  sx={{ position: "absolute", top: 10, right: 10 }}
                >
                  <PhotoCamera />
                </IconButton>
              </label>
              <ProfileAvatar src={profilePicture} alt={userProfile.full_name} />
              <label htmlFor="profile-picture">
                <Input
                  accept="image/*"
                  id="profile-picture"
                  name="profile_picture"
                  type="file"
                  onChange={handleProfilePictureChange}
                />
                <IconButton
                  color="primary"
                  aria-label="upload picture"
                  component="span"
                  sx={{
                    position: "absolute",
                    zIndex: "3",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                  }}
                >
                  <PhotoCamera />
                </IconButton>
              </label>
              <CardContent sx={{ mt: 7 }}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Typography variant="h5">{formData.full_name}</Typography>
                  <Typography variant="subtitle1" color="text.secondary">
                    {meslek[userProfile.kullanici_tipi]}
                  </Typography>
                  <Typography variant="body2" align="center" sx={{ mt: 2 }}>
                    {formData.about}
                  </Typography>
                </Box>
              </CardContent>
              {/* Ek bilgiler / üyelik tarihleri vb. */}
              <Box sx={{ p: 2, borderTop: 1, borderColor: "divider" }}>
                <Grid container spacing={2} justifyContent="space-around">
                  <Grid item>
                    <Typography variant="h6" align="center">
                      {userProfile.isleyebilecegi_evrak_fatura_sayisi} adet /
                      Aylık
                    </Typography>
                    <Typography variant="caption" align="center">
                      İşleyebilecek Evrak Sayısı
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="h6" align="center">
                      {userProfile.ekleyebilecegi_mukellef_sayisi} mükellef
                    </Typography>
                    <Typography variant="caption" align="center">
                      Ekleyebileceğiniz Mükellef Sayısı
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
              <Box sx={{ p: 2, borderTop: 1, borderColor: "divider" }}>
                <Grid container spacing={2} justifyContent="space-around">
                  <Grid item>
                    <Typography variant="h6" align="center">
                      {new Date(
                        userProfile.membership_start_date
                      ).toLocaleDateString()}
                    </Typography>
                    <Typography variant="caption" align="center">
                      Üyeliğinizin Başlangıç Tarihi
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="h6" align="center">
                      {new Date(
                        userProfile.membership_end_date
                      ).toLocaleDateString()}
                    </Typography>
                    <Typography variant="caption" align="center">
                      Üyeliğinizin Yenilenme Tarihi
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
              {/* Referans linki oluşturma bölümü */}
              <Box
                sx={{
                  mt: 3,
                  p: 2,
                  border: "1px solid #ccc",
                  backgroundColor: "tableColor.main",
                }}
              >
                <Typography variant="h6" align="center" sx={{ mb: 2 }}>
                  Referans Linkinizi Oluşturun
                </Typography>
                <Typography variant="body2" align="center" sx={{ mb: 3 }}>
                  Taban komisyon oranınız: 20% <br />
                  Arkadaşlarınızın Komisyon İndirim Oranını Belirleyin:
                </Typography>
                <Grid container spacing={2} justifyContent="center">
                  <Grid item>
                    <Typography variant="body1">Alacağınız:</Typography>
                    <Typography variant="h5">{20 - selectedRate}%</Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="body1">
                      Arkadaşlarınız Alacağı:
                    </Typography>
                    <Typography variant="h5">{selectedRate}%</Typography>
                  </Grid>
                </Grid>
                <Box sx={{ mt: 3, textAlign: "center" }}>
                  <ToggleButtonGroup
                    value={selectedRate}
                    exclusive
                    onChange={handleRateChange}
                    aria-label="Komisyon Oranı Seçimi"
                  >
                    <ToggleButton value={0} aria-label="0%">
                      0%
                    </ToggleButton>
                    <ToggleButton value={5} aria-label="5%">
                      5%
                    </ToggleButton>
                    <ToggleButton value={10} aria-label="10%">
                      10%
                    </ToggleButton>
                    <ToggleButton value={15} aria-label="15%">
                      15%
                    </ToggleButton>
                    <ToggleButton value={20} aria-label="20%">
                      20%
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Box>
                <Box sx={{ mt: 3, textAlign: "center" }}>
                  <Button
                    variant="contained"
                    color="btnActiveColor"
                    onClick={handleCreateReferralLink}
                  >
                    Referans Linkinizi Oluşturun
                  </Button>
                </Box>
              </Box>
            </StyledCard>
          </Grid>

          {/* SAĞ SÜTUN: Profil Bilgileri Düzenleme Formu */}
          <Grid item xs={12} lg={8}>
            <Paper
              elevation={3}
              sx={{
                p: 3,
                backgroundColor: "tableColor.main",
                color: "secondary.second",
              }}
            >
              <Typography variant="h5">Profilini Güncelle</Typography>
              <Typography variant="body2" mb={2}>
                Bilgilerinizde değişiklik yaptıktan sonra 'Güncelle' butonuna
                tıklamayı unutmayınız!
              </Typography>
              <Box>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      label="Adınız"
                      name="first_name"
                      value={formData.first_name || ""}
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      label="Soyadınız"
                      name="last_name"
                      value={formData.last_name || ""}
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextField
                      fullWidth
                      label="Email Adresiniz"
                      type="email"
                      name="email"
                      value={formData.email || ""}
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextField
                      fullWidth
                      label="Telefon"
                      name="phone"
                      value={formData.phone || ""}
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextField
                      fullWidth
                      label="TCKN"
                      name="tckn"
                      value={formData.tckn || ""}
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      label="Vergi Kimlik No"
                      name="vkn_no"
                      value={formData.vkn_no || ""}
                      onChange={handleInputChange}
                      required
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      label="Mühür No"
                      name="muhur_no"
                      value={formData.muhur_no || ""}
                      onChange={handleInputChange}
                      required
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      label="Vergi Dairesi"
                      name="vergi_dairesi"
                      value={formData.vergi_dairesi || ""}
                      onChange={handleInputChange}
                      required
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      label="Bağlı Olduğunuz Oda"
                      name="bagli_oldugu_oda"
                      value={formData.bagli_oldugu_oda || ""}
                      onChange={handleInputChange}
                      required
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Adresiniz"
                      name="ymm_adres"
                      value={formData.ymm_adres || ""}
                      onChange={handleInputChange}
                      required
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextField
                      fullWidth
                      label="Şehir"
                      name="city"
                      value={formData.city || ""}
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextField
                      fullWidth
                      label="Ülke"
                      name="country"
                      value={formData.country || ""}
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextField
                      fullWidth
                      label="Posta Kodu"
                      name="postal_code"
                      value={formData.postal_code || ""}
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Hakkınızda"
                      name="biografi_hakkinda"
                      multiline
                      rows={4.7}
                      value={formData.biografi_hakkinda || ""}
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Box
                      sx={{ display: "flex", justifyContent: "center", mt: 2 }}
                    >
                      <MyButton
                        type="submit"
                        variant="contained"
                        color="btnActiveColor"
                        title="Güncelle"
                        width="25%"
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Box>
      {/* Referans Linki Dialog */}
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Referans Linki Oluşturuldu</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Referans linkiniz oluşturuldu ve indirim oranınız {selectedRate}%
            olarak belirlendi. Artık, e-posta adresinizle yapılan kayıtlarda{" "}
            {selectedRate}% indirim hakkı kazanıyorsunuz .
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="btnActiveColor">
            Kapat
          </Button>
          <Button onClick={handleCloseDialog} color="btnActiveColor" autoFocus>
            Tamam
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Profile;

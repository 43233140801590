import React, { useEffect, useState } from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import {
  Box,
  Typography,
  useTheme,
  Divider,
  Grid,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  
} from "@mui/material";
import useAxios from "../../hooks/useAxios";
import { notify } from "../../helper/notify";
import MyButton from "../Commons/MyButton";
import TableComponent from "../Tables/TableComponent";
import { Table } from "reactstrap";

export const customStyles = {
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected
      ? "#454F5B"
      : state.isFocused
      ? "#D3D3D3"
      : "#fff",
    color: state.isSelected ? "#fff" : "#454F5B",
    padding: 10,
  }),
};

// Define columns for sold and materials tables
const soldColumns = [
  { Header: "Fatura No", accessor: "kod" },
  { Header: "Fatura Tarihi", accessor: "tarih" },
  { Header: "Ürün Adı", accessor: "urun" },
  { Header: "Ürün Miktarı", accessor: "miktar" },
  { Header: "Ürün Cinsi", accessor: "paket" },
];

const materialsColumns = [
  { Header: "Fatura No", accessor: "kod" },
  { Header: "Fatura Tarihi", accessor: "tarih" },
  { Header: "Ürün Adı", accessor: "urun" },
  { Header: "Ürün Miktarı", accessor: "miktar" },
  { Header: "Ürün Çeşidi", accessor: "paket" },
  { Header: "Toplam Fiyat", accessor: "fiyat" },
  { Header: "Kullanım Yüzdesi", accessor: "kullanimyuzde", isEditable: true },
  { Header: "Kullanım Miktarı", accessor: "kullanimmiktar", isEditable: true },
];

function UrunEslestirme({
  currentTCKN,
  getData,
  exportDate,
  editingData = null,
  isEditing = false,
  setCounterUrun,
}) {
  const [selectedMaterials, setSelectedMaterials] = useState([]);
  const [materials, setMaterials] = useState([]);
  const [soldMaterials, setSoldMaterials] = useState([]);
  const [selectedSoldMaterials, setSelectedSoldMaterials] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [materialsTableData, setMaterialsTableData] = useState([]);
  const [yuklenimMiktari, setYuklenimMiktari] = useState("");
  const [kdvToplam, setKdvToplam] = useState("");
  const [newKdvToplami, setNewKdvToplami] = useState("");
  const [guncellenecekKdv, setGuncellenecekKdv] = useState("");
  const [konuEdilmeyenMiktar, setKonuEdilmeyenMiktar] = useState("");
  const axiosWithToken = useAxios();
  const theme = useTheme();

  // Yardımcı fonksiyon:
  // - Sayı ise iki ondalık basamağa yuvarlar
  // - Geçersizse "Bilgi mevcut değil." gösterir
  const formatValue = (val) => {
    if (val === null || val === undefined || val === "") {
      return "Bilgi mevcut değil.";
    }
    const num = parseFloat(val);
    return isNaN(num) ? "Bilgi mevcut değil." : num.toFixed(2);
  };

  useEffect(() => {
    const { year, month } = exportDate || {};
    if (currentTCKN && year && month) {
      fetchData(currentTCKN, year, month);
    }
  }, [currentTCKN, exportDate]);

  const fetchData = async (currentTCKN, year, month) => {
    try {
      const response = await axiosWithToken.get(
        `/api/yuklenilecek_kdv/yuklenim_arti_gcb_merge?tckn=${currentTCKN}&year=${year}&month=${month}`
      );
      console.log("API Response:", response.data);

      const {
        materials = [],
        missing_products = [],
        yuklenim_miktari,
        kdv_toplam,
      } = response.data;

      // YüklenimMiktari ve kdv_toplam değerlerini sayıya çevirerek saklayalım
      const numericYuklenim = yuklenim_miktari
        ? parseFloat(String(yuklenim_miktari).match(/[\d.]+/)[0])
        : 0;
      const numericKdvToplam = kdv_toplam
        ? parseFloat(String(kdv_toplam).match(/[\d.]+/)[0])
        : 0;

      setYuklenimMiktari(numericYuklenim);
      setKdvToplam(numericKdvToplam);

      const formattedMaterials = materials.map((item) => {
        const data = item.split(", ");
        return {
          value: item,
          label: `${data[0]}, ${data[1]}, ${data[2]}, ${data[3]}, ${data[4]}, ${data[5]}`,
        };
      });

      const formattedSoldMaterials = missing_products.map((item) => {
        const data = item.split(", ");
        return {
          value: item,
          label: `${data[0]}, ${data[1]}, ${data[2]}, ${data[3]}, ${data[4]}`,
        };
      });

      setMaterials(formattedMaterials);
      setSoldMaterials(formattedSoldMaterials);
    } catch (error) {
      console.error("Veri çekilirken bir hata oluştu:", error);
      if (error.response && error.response.status === 401) {
        notify("Yetkilendirme hatası: Lütfen oturum açın.", "error");
      }
    }
  };

  useEffect(() => {
    if (selectedSoldMaterials) {
      const data = selectedSoldMaterials.value.split(", ");
      const rowData = {
        kod: data[0],
        tarih: data[1],
        urun: data[2],
        miktar: data[3],
        paket: data[4],
      };
      setTableData([rowData]);
    } else {
      setTableData([]);
    }
  }, [selectedSoldMaterials]);

  const handleSoldMaterialChange = (selectedOption) => {
    setSelectedSoldMaterials(selectedOption);
  };

  const handleMaterialChange = (selectedOptions) => {
    setSelectedMaterials(selectedOptions || []);

    const formattedData = (selectedOptions || []).map((option) => {
      const data = option.value.split(", ");
      return {
        kod: data[0],
        tarih: data[1],
        urun: data[2],
        miktar: data[3],
        paket: data[4],
        fiyat: parseFloat(data[5]),
        kullanimyuzde: "",
        kullanimmiktar: "",
      };
    });
    setMaterialsTableData(formattedData);
  };

  const handleCellEdit = (rowIndex, columnId, value) => {
    setMaterialsTableData((prevData) =>
      prevData.map((row, index) => {
        if (index === rowIndex) {
          const newRow = { ...row };

          if (columnId === "kullanimyuzde") {
            if (value < 0 || value > 100) {
              notify("Kullanım yüzdesi 0 ile 100 arasında olmalıdır.", "error");
              return row;
            }
            newRow.kullanimyuzde = value;
            newRow.kullanimmiktar = (
              (newRow.miktar * parseFloat(value)) /
              100
            ).toFixed(2);
          } else if (columnId === "kullanimmiktar") {
            if (value < 0) {
              notify("Kullanım miktarı negatif olamaz.", "error");
              return row;
            }
            newRow.kullanimmiktar = value;
            newRow.kullanimyuzde = (
              (parseFloat(value) / newRow.miktar) *
              100
            ).toFixed(2);
          }
          return newRow;
        }
        return row;
      })
    );
  };

  // Hesaplama (birim fiyat * kullanım miktarı) => newKdvToplami
  useEffect(() => {
    const yuklenimMiktarVal = yuklenimMiktari
      ? parseFloat(String(yuklenimMiktari).match(/[\d.]+/)[0])
      : 0;
    const toplamKdvVal = kdvToplam
      ? parseFloat(String(kdvToplam).match(/[\d.]+/)[0])
      : 0;

    const newCalculationValue = materialsTableData.reduce((sum, row) => {
      const miktar = parseFloat(row.miktar) || 0;
      const toplamFiyat = parseFloat(row.fiyat) || 0;
      const kullanimMiktar = parseFloat(row.kullanimmiktar) || 0;

      if (miktar === 0) return sum;
      const birimFiyat = toplamFiyat / miktar; // Birim Fiyat
      return sum + birimFiyat * kullanimMiktar;
    }, 0);

    setNewKdvToplami(newCalculationValue);

    const guncelKdv = toplamKdvVal + newCalculationValue;
    setGuncellenecekKdv(guncelKdv);

    if (yuklenimMiktarVal && guncelKdv) {
      const edilmemisMiktar = yuklenimMiktarVal - guncelKdv;
      setKonuEdilmeyenMiktar(edilmemisMiktar);
    } else {
      setKonuEdilmeyenMiktar(0);
    }
  }, [materialsTableData, yuklenimMiktari, kdvToplam, konuEdilmeyenMiktar]);

  const handleSubmit = async () => {
    if (!selectedSoldMaterials) {
      notify("Lütfen Satışlar kısmında satılan malzemeyi seçin.", "error");
      return;
    }
    if (selectedMaterials.length === 0) {
      notify("Lütfen Hammadde kısmında en az bir malzeme seçin.", "error");
      return;
    }
    const kullanilmayanMalzemeler = materialsTableData.some(
      (material) => !material.kullanimyuzde || !material.kullanimmiktar
    );
    if (kullanilmayanMalzemeler) {
      notify(
        "Lütfen tüm hammaddeler için Kullanım Yüzdesi ve Kullanım Miktarı alanlarını doldurun.",
        "error"
      );
      return;
    }
    try {
      const payload = {
        customerTCKN: currentTCKN,
        soldMaterials: [
          {
            name: tableData[0]?.urun,
            quantity: 1,
            unit: tableData[0]?.paket,
            invoice_no: tableData[0]?.kod,
            invoice_date: tableData[0]?.tarih,
            materials: materialsTableData.map((material) => ({
              name: material.urun,
              quantity: material.kullanimmiktar / tableData[0]?.miktar,
              unit: material.paket,
              invoice_no: material.kod,
              invoice_date: material.tarih,
            })),
          },
        ],
      };

      console.log("Gönderilen Payload:", payload);
      const response = await axiosWithToken.post(
        "/api/indirilecek_KDV/save_product_creation/",
        payload
      );

      if (response.status === 200) {
        notify("Form başarıyla kaydedildi.", "success");
        getData();
        resetInputs();
      } else {
        notify("Bir hata oluştu. Lütfen tekrar deneyin.", "error");
      }
    } catch (error) {
      console.error("Kayıt sırasında bir hata oluştu:", error);
      notify("Bir hata oluştu. Lütfen tekrar deneyin.", "error");
    }
  };

  const resetInputs = () => {
    setSelectedSoldMaterials(null);
    setSelectedMaterials([]);
    setTableData([]);
    setMaterialsTableData([]);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 2,
        border: `1px solid ${theme.palette.secondary.second}`,
        borderRadius: "0.2rem",
        p: 2,
        minHeight: "100%",
        backgroundColor: "tableColor.main",
      }}
    >
      <Typography variant="h5" color="secondary.second">
        Ürün İmalat Reçetesi
      </Typography>

      {/* Ürün (Satışlar) ve KDV Bilgileri aynı satırda */}
      <Box sx={{ display: "flex", gap: 2 }}>
        {/* SOL SÜTUN: Ürün/Product (Satışlar) */}
        <Box sx={{ flex: 1 }}>
          <Typography variant="h6" color="secondary.second">
            Ürün/Product (Satışlar)
          </Typography>
          <Select
            components={makeAnimated()}
            options={soldMaterials}
            value={selectedSoldMaterials}
            onChange={handleSoldMaterialChange}
            placeholder="Satılan malzemeyi seçin"
            menuPlacement="top"
            styles={customStyles}
          />
          {selectedSoldMaterials && (
            <Box
              sx={{
                border: "1px solid",
                borderColor: "secondary.second",
                p: 1,
                mt: 2,
              }}
            >
              <TableComponent
                data={tableData}
                tableColumns={soldColumns}
                showFatura={true}
              />
              <Grid container display="flex" justifyContent="end" spacing={1}>
                <Grid item>
                  <MyButton
                    title="Kapat"
                    variant="contained"
                    color="btnActiveColor"
                    onClick={() => setTableData([])}
                  >
                    Kapat
                  </MyButton>
                </Grid>
              </Grid>
            </Box>
          )}
        </Box>

        {/* SAĞ SÜTUN: KDV Bilgileri */}
        <Box sx={{ flex: 1 }}>
          <Typography variant="h6" color="secondary.second">
            KDV Bilgileri
          </Typography>
          <TableContainer sx={{ border: "1px solid #ddd", borderRadius: 1, mt: 2 }}>
            <Table>
              <TableHead sx={{ backgroundColor: "btnActiveColor.main" }}>
                <TableRow>
                  <TableCell
                    sx={{
                      color: "#fff",
                      fontWeight: "bold",
                      textAlign: "center",
                    }}
                  >
                    Yüklenilecek KDV Miktarı
                  </TableCell>
                  <TableCell
                    sx={{
                      color: "#fff",
                      fontWeight: "bold",
                      textAlign: "center",
                    }}
                  >
                    Toplam KDV
                  </TableCell>
                  <TableCell
                    sx={{
                      color: "#fff",
                      fontWeight: "bold",
                      textAlign: "center",
                    }}
                  >
                    Yüklenilecek KDV Listesi'nde yeni konu edilen toplam
                  </TableCell>
                  <TableCell
                    sx={{
                      color: "#fff",
                      fontWeight: "bold",
                      textAlign: "center",
                    }}
                  >
                    Güncellenecek olan toplam KDV
                  </TableCell>
                  <TableCell
                    sx={{
                      color: "#fff",
                      fontWeight: "bold",
                      textAlign: "center",
                    }}
                  >
                    Konu edilmeyen yeni miktar
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  {/* Yüklenilecek KDV Miktarı */}
                  <TableCell
                    sx={{
                      textAlign: "center",
                      fontSize: "1.2rem",
                      fontWeight: "bold",
                    }}
                  >
                    {formatValue(yuklenimMiktari)}
                  </TableCell>
                  {/* Toplam KDV */}
                  <TableCell
                    sx={{
                      textAlign: "center",
                      fontSize: "1.2rem",
                      fontWeight: "bold",
                    }}
                  >
                    {formatValue(kdvToplam)}
                  </TableCell>
                  {/* Yeni KDV Toplamı */}
                  <TableCell
                    sx={{
                      textAlign: "center",
                      fontSize: "1.2rem",
                      fontWeight: "bold",
                    }}
                  >
                    {formatValue(newKdvToplami)}
                  </TableCell>
                  {/* Güncellenecek KDV */}
                  <TableCell
                    sx={{
                      textAlign: "center",
                      fontSize: "1.2rem",
                      fontWeight: "bold",
                      color: "#298a31", 
                    }}
                  >
                    {formatValue(guncellenecekKdv)}
                  </TableCell>
                  {/* Konu Edilmeyen Miktar */}
                  <TableCell
                    sx={{
                      textAlign: "center",
                      fontSize: "1.2rem",
                      fontWeight: "bold",
                      color: "#8a2929",
                    }}
                  >
                    {formatValue(konuEdilmeyenMiktar)}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>

      <Divider />

      <Typography variant="h6" color="secondary.second">
        Hammadde (Alışlar)
      </Typography>
      <Select
        components={makeAnimated()}
        isMulti
        options={materials}
        value={selectedMaterials}
        onChange={handleMaterialChange}
        placeholder="Malzemeleri seçin"
        menuPlacement="top"
        styles={customStyles}
      />

      {selectedMaterials.length > 0 && (
        <Box
          sx={{
            border: "1px solid",
            borderColor: "secondary.second",
            p: 1,
            mt: 2,
          }}
        >
          <TableComponent
            data={materialsTableData}
            tableColumns={materialsColumns}
            onCellEdit={handleCellEdit}
            showFatura={true}
          />
          <Grid container display="flex" justifyContent="end" spacing={1}>
            <Grid item>
              <MyButton
                title="Kapat"
                variant="contained"
                color="btnActiveColor"
                onClick={() => setMaterialsTableData([])}
              >
                Kapat
              </MyButton>
            </Grid>
          </Grid>
        </Box>
      )}

      <Box sx={{ width: "25%", display: "flex", m: "auto" }}>
        <MyButton
          variant="contained"
          color="btnActiveColor"
          onClick={handleSubmit}
          title={editingData?.id ? "Güncelle" : "Kaydet"}
        />
      </Box>
    </Box>
  );
}

export default UrunEslestirme;

import React, { useState } from "react";
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from "@mui/material";
import { newTeamFriends } from "../../helper/initialData";
import { useUpdateTeamMemberMutation, usePostTeamMemberMutation } from "../../services/teamMembers";

const TeamFriendsForm = ({
  isVisible,
  toggleModal,
  initialState,
}) => {
  const [postTeamMember] = usePostTeamMemberMutation()
  const [updateTeamMember] = useUpdateTeamMemberMutation()
  const [formData, setFormData] = useState(initialState || newTeamFriends);
  const [errors, setErrors] = useState({
    ekip_arkadasi_adi_soyadi: "",
    telefon: "",
    email: "",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();    if (formData.id) {
      updateTeamMember(formData)
    } else {
      postTeamMember(formData);
    }
    toggleModal();
  };

  return (
    <Dialog open={isVisible}>
      <DialogTitle>
        {formData.id ? "Ekip Arkadaşı Güncelle" : "Yeni Ekip Arkadaşı Kaydı"}
      </DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit}>
          <TextField
            fullWidth
            label="Ad/SoyAd"
            name="ekip_arkadasi_adi_soyadi"
            value={formData.ekip_arkadasi_adi_soyadi}
            onChange={handleChange}
            error={!!errors.ekip_arkadasi_adi_soyadi}
            helperText={errors.ekip_arkadasi_adi_soyadi}
            margin="dense"
          />
           <TextField
            fullWidth
            label="Kullanıcı Adı"
            name="username"
            value={formData.username}
            onChange={handleChange}
            error={!!errors.username}
            helperText={errors.username}
            margin="dense"
          />
          <TextField
            fullWidth
            label="Telefon Numarası"
            name="telefon"
            value={formData.telefon}
            onChange={handleChange}
            error={!!errors.telefon}
            helperText={errors.telefon}
            margin="dense"
          />
          <TextField
            fullWidth
            label="E-mail Adresi"
            name="email"
            value={formData.email}
            onChange={handleChange}
            error={!!errors.email}
            helperText={errors.email}
            margin="dense"
          />
          <Button
            fullWidth
            type="submit"
            variant="contained"
            color="btnActiveColor"
            sx={{ color: "white" }}
          >
            {formData.id ? "Güncelle" : "Kaydet"}
          </Button>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={toggleModal} color="btnColor">
          İptal
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TeamFriendsForm;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import PageHeader from "../components/Commons/PageHeader";
import MyButton from "../components/Commons/MyButton";
import CustomerRegistrationModal from "../components/Forms/CustomerForm";
import { newCustomer } from "../helper/initialData";

import Box from "@mui/material/Box";
import { Alert, Grid } from "@mui/material";
import CustomerSelect from "../components/Commons/CustomerSelect";
import Loading from "../components/Commons/Loading";
import { useGetCustomersQuery } from "../services/customers";
import IndirimliOranListesi from "../components/IadeTurleriIslemleri/IndirimliOranListesi";

const IndirimliOran = () => {
  // 1) Modal Aç/Kapat
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // 2) Mükellef (Proje) listesini çekmek için RTK Query
  const { isLoading: loading } = useGetCustomersQuery();

  // 3) Seçili müşteri/proje state (ID veya TCKN)
  const [selectedMusteri, setSelectedMusteri] = useState("");

  // 4) Sayfa içerisinde gösterilecek kısımlar
  // (burada sadece "indirimliOran" listesi var)
  const [show, setShow] = useState({
    indirimliOran: false,
  });

  const handleShow = (type) => {
    setShow((prev) => ({
      ...prev,
      indirimliOran: type === "indirimliOran",
    }));
  };

  // Eğer seçili müşteri yoksa, "indirimliOran" adımını kapat
  useEffect(() => {
    if (!selectedMusteri) {
      setShow({
        indirimliOran: false,
      });
    }
  }, [selectedMusteri]);

  if (loading) return <Loading />;

  return (
    <Box sx={{ p: 1 }}>
      <PageHeader text="İndirimli Oran" />

      <Grid container spacing={3} flexDirection="column" mt={1}>
        <Grid item xs={8} md={3}>
          <MyButton
            variant="contained"
            color="btnActiveColor"
            onClick={handleOpen}
            title="Yeni Mükellef Kaydı Oluştur"
          />
        </Grid>

        {/* Müşteri/Proje Seçimi */}
        <Grid item xs={10} md={5}>
          <CustomerSelect
            selectedMusteri={selectedMusteri}
            setSelectedMusteri={setSelectedMusteri}
            msg="İşlem yapmak istediğiniz projeyi seçiniz veya arama yapmak için yazınız"
          />
        </Grid>
      </Grid>

      {/* Proje seçilmemişse uyarı göster */}
      {!selectedMusteri && (
        <Alert severity="warning" sx={{ mt: 2, mb: 2 }}>
          İşlem yapmak istediğiniz projeyi seçiniz veya arama yapmak için
          yazınız
        </Alert>
      )}

      {/* Seçili müşteri varsa ek butonları göster */}
      {selectedMusteri && (
        <Box sx={{ display: "flex", flexWrap: "wrap", mt: 1, gap: 1 }}>
          <Box sx={{ flexGrow: 1 }}>
            <MyButton
              variant={show.indirimliOran ? "contained" : "outlined"}
              color={show.indirimliOran ? "btnActiveColor" : "btnColor"}
              title="İndirimli Oran Listesi"
              onClick={() => handleShow("indirimliOran")}
            />
          </Box>
        </Box>
      )}

      {/* “İndirimli Oran Listesi” butonuna tıklayınca gösterilen bileşen */}
      {show.indirimliOran && (
        <IndirimliOranListesi tckn={selectedMusteri?.value} />
      )}

      {/* Yeni Proje Kayıt Modal */}
      {open && (
        <CustomerRegistrationModal
          isVisible={open}
          toggleModal={handleClose}
          initialState={newCustomer}
        />
      )}
    </Box>
  );
};

export default IndirimliOran;

import {
  Box,
  // Box,
  Typography,
} from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Toolbar from "@mui/material/Toolbar";
import React from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
const icon = (name) => `/assets/navbar/${name}.svg`;

const ymmLinks = [
  {
    title: "Stok-Cari Görüntüle",
    url: "/musavir-online",
    icon: icon("stok"),
  },
  {
    title: "Mükelleflerim",
    url: "/musavir-online/mukelleflerim",
    icon: icon("firms"),
  },
  {
    title: "Fatura Kayıtlarını Görüntüle",
    url: "/musavir-online/islenmis-faturalar/",
    icon: icon("financia"),
  },
  {
    title: "Mal İhracatı",
    url: "/musavir-online/mal-ihracati",
    icon: icon("purchase"),
  },

  // {
  //   title: "İndirimli Oran",
  //   url: "/musavir-online/indirimli-oran",
  //   icon: icon("purchase"),
  // },

  {
    title: "İhraç Kayıtlı Teslim",
    url: "/musavir-online/ihrac-kayitli-teslim",
    icon: icon("sales"),
  },
  // {
  //   title: "İndirimli Orana Tabi KDV Listesi",
  //   url: "/musavir-online/indirimli-orana-tabi-kdv-listesi",
  //   icon: icon("ic_analytics"),
  // },
  {
    title: "Tevkifatlı Satışlarda İade",
    url: "/musavir-online/tevkifatli-satislarda-iade",
    icon: icon("sales"),
  },
  // {
  //   title: "Uluslararası Nakliye İadesi",
  //   url: "/musavir-online/uluslararası-nakliye-iadesi",
  //   icon: icon("ic_analytics"),
  // },

  {
    title: "Ekip Arkadaşlarım",
    icon: icon("firms"),
    url: "/musavir-online/ekip-arkadaslarim/",
  },
  {
    title: "Profilim",
    icon: icon("sales"),
    url: "/musavir-online/profilim/",
  },

  // {
  //   title: "Paket Yükselt",
  //   icon: icon("brand"),
  //   url: "/musavir-online/paket-yukselt/",
  // },
];

const smmLinks = [
  {
    title: "Stok-Cari Görüntüle",
    url: "/musavir-online",
    icon: icon("stok"),
  },
  {
    title: "Mükelleflerim",
    url: "/musavir-online/mukelleflerim",
    icon: icon("firms"),
  },
  {
    title: "İşlem Merkezi",
    url: "/musavir-online/islem-merkezi",
    icon: icon("firms"),
  },
  {
    title: "Fatura Kayıtlarını Görüntüle",
    url: "/musavir-online/islenmis-faturalar/",
    icon: icon("financia"),
  },
  {
    title: "Ekip Arkadaşlarım",
    icon: icon("firms"),
    url: "/musavir-online/ekip-arkadaslarim/",
  },
  {
    title: "Profilim",
    icon: icon("sales"),
    url: "/musavir-online/profilim/",
  },

  // {
  //   title: "Paket Yükselt",
  //   icon: icon("brand"),
  //   url: "/musavir-online/paket-yukselt/",
  // },
];
const standartLinks = [
  {
    title: "Profilim",
    icon: icon("sales"),
    url: "/musavir-online/profilim/",
  },
  {
    title: "Ekip Arkadaşlarım",
    icon: icon("firms"),
    url: "/musavir-online/ekip-arkadaslarim/",
  },
  // {
  //   title: "Paket Yükselt",
  //   icon: icon("brand"),
  //   url: "/musavir-online/paket-yukselt/",
  // },
];

const externalLinks = [
  {
    title: "Paketleri Görüntüle",
    icon: icon("brand"),
    url: `${process.env.REACT_APP_BASE_URL}#pricing`,
  },
];

const userLinks = {
  smm: smmLinks,
  ymm: ymmLinks,
  standart: standartLinks,
};

const iconStyle = {
  color: "primary",
  borderRadius: "1rem",
  "&:hover": {
    backgroundColor: "secondary.main",
    color: "white",
  },
};
const selectedStyle = {
  backgroundColor: "#0092CA",
  borderRadius: "1rem",
  "&:hover": {
    backgroundColor: "rgb(0, 146, 202,0.7)",
    color: "secondary.second",
  },
  color: "white",
};

const MenuListItems = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { currentUser } = useSelector((state) => state.auth);
  const theme = useTheme();
  const textColor = theme.palette.mode === "dark" ? "#EEEEEE" : "#000000";
  return (
    <div>
      <Toolbar
        sx={{
          flexDirection: "row",
          alignItems: "center",
          gap: 1,
          color: textColor,
          cursor: "pointer",
        }}
        onClick={() => {
          window.open("https://www.musavironline.com/", "_blank");
        }}
      >
        {/* Logo */}
        <Box
          component="img"
          src="/favicon.png"
          alt="Müşavir Online Logo"
          sx={{
            width: 40,
            height: 40,
            borderRadius: "50%",
          }}
        />

        {/* Yazılar */}
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Typography variant="h6" component="div">
            Müşavir Online
          </Typography>
          <Typography variant="caption">G - 5 Tech</Typography>
        </Box>
      </Toolbar>

      <List>
        {userLinks[
          (currentUser?.ekiparkadasi?.ekip_lideri_kullanici_tipi ||
            currentUser?.kullanici_tipi) ??
            "standart"
        ].map((item, index) => (
          <ListItem key={item.title} disablePadding>
            <ListItemButton
              onClick={() => navigate(item.url)}
              sx={pathname === item.url ? selectedStyle : iconStyle}
            >
              {/* <Box
                sx={{
                  backgroundImage: `url(${item.icon})`,
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  width: 48,
                  height: 48,
                  backgroundColor: "red",
                }}
              /> */}

              {/* <Box
                  sx={{
                    width: 24,
                    height: 24,
                    mask: `url(${item.icon}) no-repeat center / contain`,
                    mr: 2,
                    bgcolor: "currentColor",
                  }}
                /> */}
              <ListItemText primary={item.title} />
            </ListItemButton>
          </ListItem>
        ))}
        {externalLinks.map((item, index) => (
          <ListItem key={item.title} disablePadding>
            <ListItemButton
              component="a"
              href={item.url}
              target="_blank"
              rel="noopener noreferrer"
              sx={iconStyle}
            >
              <ListItemText primary={item.title} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </div>
  );
};

export default MenuListItems;

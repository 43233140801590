// src/components/Forms/IndirimliKayitForm.js

import React, { useState } from "react";
import {
  Button,
  TextField,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Box,
  Typography,
  FormControlLabel,
} from "@mui/material";

// Eski (customers) servisleriniz
import {
  usePostCustomerMutation,
  useUpdateCustomerMutation,
} from "../../services/customers";

// YENİ RTK Query servis (indirimliOran)
import {
  useCreateOrSelectProjectMutation,
  useAddWorkerStatisticsMutation,
  useAddIndependentSectionMutation,
  useCreateBuildingMutation,
  useAddHousingDetailsMutation,
} from "../../services/indirimliOran";

// -------------------------------------------------
// 1) Yardımcı Fonksiyonlar: getMonthCount, getMonthNameTR, computeMonthLabel
// -------------------------------------------------
function getMonthCount(start, end) {
  if (!start || !end) return 0;
  const [startYear, startM] = start.split("-").map(Number);
  const [endYear, endM] = end.split("-").map(Number);
  return (endYear - startYear) * 12 + (endM - startM) + 1;
}

function getMonthNameTR(monthNumber) {
  const aylar = [
    "Ocak",
    "Şubat",
    "Mart",
    "Nisan",
    "Mayıs",
    "Haziran",
    "Temmuz",
    "Ağustos",
    "Eylül",
    "Ekim",
    "Kasım",
    "Aralık",
  ];
  return aylar[monthNumber - 1];
}

function computeMonthLabel(startYear, startMonth, index) {
  const totalMonthsFromStart = startMonth - 1 + index;
  const year = startYear + Math.floor(totalMonthsFromStart / 12);
  const month = (totalMonthsFromStart % 12) + 1;
  return `${getMonthNameTR(month)} ${year}`;
}

// -------------------------------------------------
// 2) Varsayılan daire (housing_details) objesi
// -------------------------------------------------
const defaultHousingItem = {
  floor: "1",
  section_no: "1",
  characteristic: "Mesken",
  net_area: 0,
  gross_area: 0,
  is_sold: false,
  invoices: [],
  product_id: null,
};

// -------------------------------------------------
// 3) Ana Bileşen: IndirimliKayitForm
// -------------------------------------------------
const IndirimliKayitForm = ({
  isVisible,
  toggleModal,
  formData,
  setFormData,
  formSection, // <-- (Opsiyonel) Hangi bölümü göstereceğimizi kontrol etmek için
}) => {
  // Eski RTK Query (customers)
  const [updateCustomer] = useUpdateCustomerMutation();
  const [postCustomer] = usePostCustomerMutation();

  // YENİ RTK Query (indirimliOran)
  const [createProject] = useCreateOrSelectProjectMutation();
  const [addWorkerStats] = useAddWorkerStatisticsMutation();
  const [addIndependentSection] = useAddIndependentSectionMutation();
  const [createBuilding] = useCreateBuildingMutation();
  const [addHousingDetails] = useAddHousingDetailsMutation();

  // 3.1) Form verisi

  // 3.2) Hata ve uyarı state’leri
  const [errors, setErrors] = useState({});
  const [modalMessage, setModalMessage] = useState("");
  const [successModalVisible, setSuccessModalVisible] = useState(false);

  // 3.3) İşçi istatistikleri için ek state’ler
  const [startMonth, setStartMonth] = useState("");
  const [endMonth, setEndMonth] = useState("");
  // shareholders => "hissedarlar" (işçi sayısı, gideri vs.)
  const [shareholders, setShareholders] = useState([]);

  // -------------------------------------------------
  // 4) onChange Fonksiyonları
  // -------------------------------------------------
  // (A) Basit alanlar
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  // (B) Nested "sections" alanları (mesken/ortak)
  const handleNestedChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => {
      const updatedSections = { ...prev.sections };
      switch (name) {
        case "mesken_count":
          updatedSections.mesken.count = value;
          break;
        case "mesken_area":
          updatedSections.mesken.area = value;
          break;
        case "ortak_count":
          updatedSections.ortak.count = value;
          break;
        case "ortak_area":
          updatedSections.ortak.area = value;
          break;
        default:
          break;
      }
      return { ...prev, sections: updatedSections };
    });
  };

  // (C) İşçi istatistikleri satırları
  const handleShareholderChange = (index, field, val) => {
    const updated = [...shareholders];
    updated[index][field] = val;
    setShareholders(updated);
  };
  const deleteShareholder = (index) => {
    const updated = shareholders.filter((_, i) => i !== index);
    setShareholders(updated);
  };

  // (D) Daire Bilgileri (housing_details)
  const addHousingItem = () => {
    setFormData((prev) => ({
      ...prev,
      housing_details: [...prev.housing_details, { ...defaultHousingItem }],
    }));
  };
  const handleHousingDetailChange = (index, field, val) => {
    setFormData((prev) => {
      const updated = [...prev.housing_details];
      if (field === "is_sold") {
        updated[index].is_sold = val;
      } else if (field === "net_area" || field === "gross_area") {
        updated[index][field] = parseFloat(val) || 0;
      } else {
        updated[index][field] = val;
      }
      return { ...prev, housing_details: updated };
    });
  };
  const removeHousingItem = (index) => {
    setFormData((prev) => {
      const updated = prev.housing_details.filter((_, i) => i !== index);
      return { ...prev, housing_details: updated };
    });
  };

  // Fatura ekleme / silme
  const addInvoice = (housingIndex) => {
    setFormData((prev) => {
      const updated = [...prev.housing_details];
      updated[housingIndex].invoices.push({ invoice_no: "" });
      return { ...prev, housing_details: updated };
    });
  };
  const handleInvoiceChange = (housingIndex, invoiceIndex, val) => {
    setFormData((prev) => {
      const updated = [...prev.housing_details];
      updated[housingIndex].invoices[invoiceIndex].invoice_no = val;
      return { ...prev, housing_details: updated };
    });
  };
  const removeInvoice = (housingIndex, invoiceIndex) => {
    setFormData((prev) => {
      const updated = [...prev.housing_details];
      updated[housingIndex].invoices = updated[housingIndex].invoices.filter(
        (_, i) => i !== invoiceIndex
      );
      return { ...prev, housing_details: updated };
    });
  };

  // (E) İşçi İstatistik Dönemi oluşturma
  const handleCreateMonths = () => {
    const total = getMonthCount(startMonth, endMonth);
    if (total <= 0) {
      setModalMessage(
        "Geçersiz tarih aralığı. Lütfen doğru bir başlangıç ve bitiş seçiniz."
      );
      setSuccessModalVisible(true);
      return;
    }
    const [startY, startM] = startMonth.split("-").map(Number);
    const newRows = [];
    for (let i = 0; i < total; i++) {
      const label = computeMonthLabel(startY, startM, i);
      newRows.push({
        period: label,
        isciSayisi: "",
        isciGideri: "",
      });
    }
    setShareholders(newRows);
  };

  // (F) Submit (Kaydet/Güncelle)
  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      // ---------------------------
      // 1) formSection === 1 => Proje Oluşturma
      // ---------------------------
      if (formSection === 1) {
        // /api/indirimli_oran/create_or_select_project/
        const rest = await createProject({
          name: formData.name,
          registration_no: formData.registration_no,
          registration_date: formData.registration_date,
        }).unwrap();
        console.log(rest);
        setFormData((prev) => ({
          ...prev,
          project_id: rest.project.id,
        }));
        toggleModal();
        return;
      }

      // ---------------------------
      // 2) formSection === 4 => İşçi İstatistikleri
      // ---------------------------
      if (formSection === 4) {
        // /api/indirimli_oran/add_worker_statistics/
        const worker_data = shareholders.map((item) => ({
          period: convertPeriodToISO(item.period),
          worker_count: Number(item.isciSayisi) || 0,
          worker_expense: Number(item.isciGideri) || 0,
        }));
        console.log(formData);
        const projectId = formData.project_id ? Number(formData.project_id) : 1;

        await addWorkerStats({
          project_id: projectId,
          worker_data,
        }).unwrap();

        toggleModal();
        return;
      }

      // ---------------------------
      // 3) formSection === 5 => Bina / Daire Bilgileri
      // ---------------------------
      if (formSection === 5) {
        // A) /api/indirimli_oran/add_independent_section/
        await addIndependentSection({
          project_id: Number(formData.project_id) || 1,
          sections: {
            mesken: {
              count: Number(formData.sections.mesken.count) || 0,
              area: Number(formData.sections.mesken.area) || 0,
            },
            ortak: {
              count: Number(formData.sections.ortak.count) || 0,
              area: Number(formData.sections.ortak.area) || 0,
            },
          },
        }).unwrap();

        // B) /api/indirimli_oran/create_building/
        await createBuilding({
          project_id: Number(formData.project_id) || 1,
          building_no: Number(formData.building_no) || 1,
          total_floors: Number(formData.total_floors) || 1,
        }).unwrap();

        // C) /api/indirimli_oran/add_housing_details/
        await addHousingDetails({
          project_id: Number(formData.project_id) || 1,
          building_id: Number(formData.building_id) || 1,
          housing_details: formData.housing_details.map((hd) => ({
            floor: hd.floor,
            section_no: hd.section_no,
            characteristic: hd.characteristic,
            net_area: Number(hd.net_area) || 0,
            gross_area: Number(hd.gross_area) || 0,
            is_sold: !!hd.is_sold,
            invoices: hd.invoices || [],
            product_id: hd.product_id || null,
          })),
        }).unwrap();

        toggleModal();
        return;
      }

      // ---------------------------
      // ESKİ (Fallback) MANTIK
      // (formSection başka bir değer ise veya geriye dönük destek)
      // ---------------------------
      if (formData.id) {
        // Mükellef Güncelle
        await updateCustomer({ ...formData, hissedarlar: shareholders });
      } else {
        // Yeni Mükellef
        await postCustomer({ ...formData, hissedarlar: shareholders });
      }
      toggleModal();
    } catch (error) {
      setModalMessage(`Hata oluştu: ${error.message}`);
      setSuccessModalVisible(true);
    }
  };

  // Yardımcı fonksiyon: “Ocak 2023” => “2023-01-01” vb.
  const convertPeriodToISO = (str) => {
    // Elinizdeki "str" formatına göre düzenleyin
    if (/^\d{4}-\d{2}$/.test(str)) {
      // "2023-01" => "2023-01-01"
      return str + "-01";
    }
    return str;
  };

  // -------------------------------------------------
  // 5) Render
  // -------------------------------------------------
  return (
    <Dialog open={isVisible} maxWidth="md" fullWidth>
      <DialogTitle>
        {formData.id ? "Mükellef Güncelle" : "Yeni Mükellef Kaydı"}
      </DialogTitle>

      <DialogContent>
        <form onSubmit={handleSubmit}>
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 2 }}>
            {/* --------------------------- 
                (A) formSection === 1 
                Proje Bilgileri 
               --------------------------- */}
            {formSection === 1 && (
              <>
                <TextField
                  label="Proje Adı"
                  name="name"
                  value={formData.name || ""}
                  onChange={handleChange}
                  error={!!errors.name}
                  helperText={errors.name || ""}
                  margin="dense"
                  required
                  fullWidth
                />

                <TextField
                  label="Proje Kayıt Numarası"
                  name="registration_no"
                  value={formData.registration_no || ""}
                  onChange={handleChange}
                  error={!!errors.registration_no}
                  helperText={errors.registration_no || ""}
                  margin="dense"
                  sx={{ width: "48%" }}
                />

                <TextField
                  label="Proje Kayıt Tarihi"
                  name="registration_date"
                  value={formData.registration_date || ""}
                  onChange={(e) => {
                    const selectedDate = e.target.value;
                    const formatted = new Date(selectedDate)
                      .toISOString()
                      .split("T")[0];
                    setFormData((prev) => ({
                      ...prev,
                      registration_date: formatted,
                    }));
                  }}
                  error={!!errors.registration_date}
                  helperText={errors.registration_date || ""}
                  margin="dense"
                  type="date"
                  InputLabelProps={{ shrink: true }}
                  required
                  sx={{ width: "48%" }}
                />
              </>
            )}

            {/* --------------------------- 
                (B) formSection === 4 
                İşçi İstatistikleri 
               --------------------------- */}
            {formSection === 4 && (
              <>
                <Typography variant="h6" sx={{ width: "100%", mt: 3, mb: 1 }}>
                  İşçi İstatistikleri Dönemi
                </Typography>
                <Typography variant="body2" sx={{ width: "100%", mb: 1 }}>
                  Lütfen başlangıç ve bitiş aylarını seçiniz. Program otomatik
                  olarak her aya ait satırları oluşturacaktır.
                </Typography>

                <Box sx={{ display: "flex", gap: 2, width: "100%" }}>
                  <TextField
                    label="Başlangıç Tarihi"
                    type="month"
                    value={startMonth}
                    onChange={(e) => setStartMonth(e.target.value)}
                    InputLabelProps={{ shrink: true }}
                    sx={{ flex: 1 }}
                  />
                  <TextField
                    label="Bitiş Tarihi"
                    type="month"
                    value={endMonth}
                    onChange={(e) => setEndMonth(e.target.value)}
                    InputLabelProps={{ shrink: true }}
                    sx={{ flex: 1 }}
                  />
                </Box>

                <Button
                  variant="contained"
                  color="btnActiveColor"
                  onClick={handleCreateMonths}
                  sx={{ textTransform: "none", mt: 1 }}
                >
                  Oluştur
                </Button>

                {/* İşçi Satırları */}
                {shareholders.map((item, index) => (
                  <Box
                    key={index}
                    sx={{
                      width: "100%",
                      mt: 2,
                      p: 1,
                      border: "1px solid #ccc",
                    }}
                  >
                    <Box sx={{ display: "flex", gap: 2, mb: 2 }}>
                      <TextField
                        label="Dönem"
                        value={item.period}
                        onChange={(e) =>
                          handleShareholderChange(
                            index,
                            "period",
                            e.target.value
                          )
                        }
                        sx={{ flex: 1 }}
                      />
                      <TextField
                        label="İşçi Sayısı"
                        type="number"
                        value={item.isciSayisi || ""}
                        onChange={(e) =>
                          handleShareholderChange(
                            index,
                            "isciSayisi",
                            e.target.value
                          )
                        }
                        sx={{ flex: 1 }}
                      />
                      <TextField
                        label="İşçi Gideri"
                        type="number"
                        value={item.isciGideri || ""}
                        onChange={(e) =>
                          handleShareholderChange(
                            index,
                            "isciGideri",
                            e.target.value
                          )
                        }
                        sx={{ flex: 1 }}
                      />
                    </Box>

                    <Button
                      variant="contained"
                      color="error"
                      onClick={() => deleteShareholder(index)}
                    >
                      Kaldır
                    </Button>
                  </Box>
                ))}
              </>
            )}

            {/* --------------------------- 
                (C) formSection === 5 
                Bina / Daire Bilgileri 
               --------------------------- */}
            {formSection === 5 && (
              <>
                <Typography variant="h6" sx={{ width: "100%", mt: 3, mb: 1 }}>
                  Bina Bilgileri
                </Typography>

                <TextField
                  label="Bina Numarası"
                  name="building_no"
                  value={formData.building_no}
                  onChange={handleChange}
                  margin="dense"
                  type="number"
                  sx={{ width: "48%" }}
                />

                <TextField
                  label="Toplam Kat Sayısı"
                  name="total_floors"
                  value={formData.total_floors}
                  onChange={handleChange}
                  margin="dense"
                  type="number"
                  sx={{ width: "48%" }}
                />

                <Typography variant="h6" sx={{ width: "100%", mt: 3, mb: 1 }}>
                  Mesken Bilgileri
                </Typography>

                <Box sx={{ display: "flex", gap: 2, width: "100%" }}>
                  <TextField
                    label="Daire Sayısı (Mesken)"
                    name="mesken_count"
                    type="number"
                    value={formData.sections.mesken.count}
                    onChange={handleNestedChange}
                    sx={{ flex: 1 }}
                  />
                  <TextField
                    label="Toplam m² (Mesken)"
                    name="mesken_area"
                    type="number"
                    value={formData.sections.mesken.area}
                    onChange={handleNestedChange}
                    sx={{ flex: 1 }}
                  />
                </Box>

                <Typography variant="h6" sx={{ width: "100%", mt: 3, mb: 1 }}>
                  Ortak Alan Bilgileri
                </Typography>
                <Box sx={{ display: "flex", gap: 2, width: "100%" }}>
                  <TextField
                    label="Ortak Alan Sayısı"
                    name="ortak_count"
                    type="number"
                    value={formData.sections.ortak.count}
                    onChange={handleNestedChange}
                    sx={{ flex: 1 }}
                  />
                  <TextField
                    label="Toplam m² (Ortak)"
                    name="ortak_area"
                    type="number"
                    value={formData.sections.ortak.area}
                    onChange={handleNestedChange}
                    sx={{ flex: 1 }}
                  />
                </Box>

                <Typography variant="h6" sx={{ width: "100%", mt: 3, mb: 1 }}>
                  Daire (Bağımsız Bölüm) Bilgileri
                </Typography>
                <Typography variant="body2" sx={{ width: "100%", mb: 1 }}>
                  Kat, daire özellikleri ve fatura bilgilerini ekleyebilirsiniz.
                </Typography>

                <Button
                  variant="contained"
                  onClick={addHousingItem}
                  color="btnActiveColor"
                  sx={{ textTransform: "none", mt: 1 }}
                >
                  Daire Ekle
                </Button>

                {formData.housing_details.map((item, idx) => (
                  <Box
                    key={idx}
                    sx={{
                      width: "100%",
                      mt: 2,
                      p: 2,
                      border: "1px solid #999",
                      borderRadius: 1,
                    }}
                  >
                    <Box sx={{ display: "flex", gap: 2, mb: 2 }}>
                      <TextField
                        label="Kat (floor)"
                        value={item.floor}
                        onChange={(e) =>
                          handleHousingDetailChange(
                            idx,
                            "floor",
                            e.target.value
                          )
                        }
                        sx={{ flex: 1 }}
                      />
                      <TextField
                        label="Daire/Section No"
                        value={item.section_no}
                        onChange={(e) =>
                          handleHousingDetailChange(
                            idx,
                            "section_no",
                            e.target.value
                          )
                        }
                        sx={{ flex: 1 }}
                      />
                      <TextField
                        label="Özellik (characteristic)"
                        value={item.characteristic}
                        onChange={(e) =>
                          handleHousingDetailChange(
                            idx,
                            "characteristic",
                            e.target.value
                          )
                        }
                        sx={{ flex: 1 }}
                      />
                    </Box>

                    <Box sx={{ display: "flex", gap: 2, mb: 2 }}>
                      <TextField
                        label="Net Alan (m²)"
                        type="number"
                        value={item.net_area}
                        onChange={(e) =>
                          handleHousingDetailChange(
                            idx,
                            "net_area",
                            e.target.value
                          )
                        }
                        sx={{ flex: 1 }}
                      />
                      <TextField
                        label="Brüt Alan (m²)"
                        type="number"
                        value={item.gross_area}
                        onChange={(e) =>
                          handleHousingDetailChange(
                            idx,
                            "gross_area",
                            e.target.value
                          )
                        }
                        sx={{ flex: 1 }}
                      />
                    </Box>

                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={item.is_sold}
                          onChange={(e) =>
                            handleHousingDetailChange(
                              idx,
                              "is_sold",
                              e.target.checked
                            )
                          }
                        />
                      }
                      label="Satıldı mı?"
                    />

                    {item.is_sold && (
                      <Box sx={{ ml: 2, mb: 2 }}>
                        <Typography variant="subtitle1" sx={{ mb: 1 }}>
                          Fatura Bilgileri
                        </Typography>
                        <Button
                          variant="outlined"
                          onClick={() => addInvoice(idx)}
                          color="btnActiveColor"
                        >
                          Fatura Ekle
                        </Button>
                        {item.invoices.map((inv, invIdx) => (
                          <Box
                            key={invIdx}
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              gap: 1,
                              mt: 1,
                            }}
                          >
                            <TextField
                              label="Fatura No"
                              value={inv.invoice_no}
                              onChange={(e) =>
                                handleInvoiceChange(idx, invIdx, e.target.value)
                              }
                              sx={{ flex: 1 }}
                            />
                            <Button
                              variant="contained"
                              color="error"
                              onClick={() => removeInvoice(idx, invIdx)}
                            >
                              SİL
                            </Button>
                          </Box>
                        ))}
                      </Box>
                    )}

                    <Button
                      variant="contained"
                      color="error"
                      onClick={() => removeHousingItem(idx)}
                    >
                      Daireyi Kaldır
                    </Button>
                  </Box>
                ))}
              </>
            )}

            {/* Ortak Kaydet/Güncelle Butonu (her bölümde aynı) */}
            <Button
              fullWidth
              type="submit"
              variant="contained"
              color="btnActiveColor"
              sx={{ mt: 3 }}
            >
              {formData.id ? "Güncelle" : "Kaydet"}
            </Button>
          </Box>
        </form>
      </DialogContent>

      <DialogActions>
        <Button onClick={toggleModal} color="btnColor">
          İptal
        </Button>
      </DialogActions>

      {/* Başarı / Hata Modal'ı */}
      <Dialog
        open={successModalVisible}
        onClose={() => setSuccessModalVisible(false)}
      >
        <DialogTitle>Uyarı</DialogTitle>
        <DialogContent>{modalMessage}</DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setSuccessModalVisible(false);
              toggleModal();
            }}
            color="primary"
          >
            Tamam
          </Button>
        </DialogActions>
      </Dialog>
    </Dialog>
  );
};

export default IndirimliKayitForm;
